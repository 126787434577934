
<template>
    <div class="through-page chart-page--container flex-item">
        <div class="common-table__title">按成分找药</div>

        <div class="through-page-panel">
            <div class="through-page-assist">
                <el-popover placement="right-start" width="280" trigger="hover">
                    <el-button slot="reference" type="primary" size="small">指引</el-button>
                    <p class="through-page-tips" v-if="activeName === 'first'">
                        1、<span class="red">红色框</span>中的数字表示该药品的成分数量；<br>
                        2、点击<span class="red">红色框</span>可以查看该药品成分及数量情况；<br>
                        3、<span class="blue">蓝色框</span>中的数字表示该药品的入药数量；<br>
                        4、点击<span class="blue">蓝色框</span>可以查看该成分入药数量、近年销售金额及排名；<br>
                    </p>
                    <p class="through-page-tips" v-else>
                        1、<span class="red">红色框</span>中的数字表示该药品的成分数量；<br>
                        2、点击<span class="red">红色框</span>可以查看该药品成分及数量情况；<br>
                        3、<span class="blue">蓝色框</span>中的数字表示该药品的成分数量；<br>
                        4、点击<span class="blue">蓝色框</span>可以查看该成分入药数量、近年销售金额及排名；<br>
                    </p>
                </el-popover>
                <div v-show="showSelect" class="through-page-assist__center">
                    <el-tabs v-model="activeName" style="margin-bottom: 10px;">
                        <el-tab-pane label="按药品查成分" name="first"></el-tab-pane>
                        <el-tab-pane label="按成分查药品" name="second"></el-tab-pane>
                    </el-tabs>

                    <div v-show="activeName === 'first' && showSelect">
                        <el-select class="flex-item" size="small" v-model="model" filterable remote reserve-keyword @change="loadData"
                                   placeholder="请输入药品关键词" :remote-method="remoteMethod" :loading="dataLoading" value-key="id">
                            <el-option v-for="item in option" :key="item.id" :label="item.comName"  :value="item"></el-option>
                        </el-select>
                    </div>

                    <div  v-show="activeName === 'second' && showSelect">
                        <el-select class="flex-item" size="small" v-model="model1" filterable remote reserve-keyword @change="loadData1"
                                   placeholder="请输入成分关键词" :remote-method="remoteMethod1" :loading="dataLoading1" value-key="id">
                            <el-option v-for="item in option1" :key="item.id" :label="item.component"  :value="item"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="through-page-assist__bottom">
                    <i class="clickable el-icon-caret-bottom" :class="{active: showSelect}" @click="showSelect = !showSelect"></i>
                </div>
            </div>

            <div class="through-page-textBottom" v-show="activeName === 'second' && JSON.stringify(baseList) !== '{}'">
                <div class="title">
                    <span style="color: #5171DA;cursor: pointer;" @click="cliclComponent(model1)">{{model1.component}}</span>
                    信息
                    <el-popover placement="right" width="100" trigger="hover" style="margin-left: 10px;">
                        <p style="margin: 0 5px;">点击成分跳转中药材价格</p>
                        <i slot="reference" class="el-icon-info popup-class__i" style="color: #45d75f;font-size: 16px;margin: 6px 6px 0 0;"></i>
                    </el-popover>
                </div>
                <div class="through-page-textBottom_box" v-show="showText">
                    <div class="box_flex" v-for="(item,index) in baseCols" :key="index">
                        <span class="box_flex_title">{{item.label}}</span>
                        <span>{{baseList[item.field] || ''}}</span>
                    </div>
                </div>
                <div class="through-page-assist__bottom">
                    <i class="clickable el-icon-caret-bottom" :class="{active: showText}" @click="showText = !showText"></i>
                </div>
            </div>

            <div class="through-page-content">
                <left-component ref="first" class="through-page-content__full" :message="message" 
                :class="{show: activeName === 'first'}" @goSecond="goSecond" v-if="finished.first" @setCountField="setCountField"></left-component>
                <right-component ref="second" class="through-page-content__full" 
                :message="message1" :class="{show: activeName === 'second'}" @setCountField="setCountField"></right-component>
            </div>
        </div>
    </div>
</template>

<script>
    import leftComponent from './component-left'
    import rightComponent from './component-right'
    export default {
        components: {leftComponent, rightComponent},
        data() {
            return {
                visitId: new Date().getTime(),
                pageId: 'throughComponent',
                pageName: '按成分找药',

                showSelect: true,

                activeName: 'first',
                finished: {
                    first: false,
                    second: false,
                },


                model: {},
                dataLoading: false,
                message: '',
                option: [],

                model1: {},
                dataLoading1: false,
                option1: [],
                message1: '',
                showText:true,
                baseCols: [
                    { label: "【药品别名】", field: "aliasName" },
                    { label: "【功效作用】", field: "efficacyEffect" },
                    { label: "【产地分布】", field: "distribution" },
                    { label: "【性味归经】", field: "tropismTaste" },
                    { label: "【主要成分】", field: "component" },
                ],
                baseList:{},
            }
        },
        mounted() {
            const txt = this.$route.query.txt
            if (txt) {
                let time = new Date()
                this.model1 = {
                    id:time,
                    component:txt
                }
                this.option1 = [
                    {
                        id:time,
                        component:txt
                    }
                ]
                this.goSecond({
                    id: txt,
                    component: txt,
                })
                this.getBaseInfo(this.model1.component)
            }
            this.$help.socket.send(this)

            this.remoteMethod('感冒灵')
            this.remoteMethod1('当归')
        },
        watch: {
            activeName: {
                handler(val) {
                    this.finished[val] = true
                },
                immediate: true
            },
        },
        methods: {
            setCountField(row){
                this.activeName = 'first'
                this.option = [row]
                this.model = {
                        id:row.id,
                        comName:row.comName
                    }
                this.loadData()
            },
            goPage(url, obj) {
                const { href } = this.$router.resolve({
                    path: url,
                    query: obj,
                });
                window.open(href, "_blank");
            },
            cliclComponent(row){
                if(row.chineseMedicinal && row.chineseMedicinalCode){
                    let obj = {
                        comCode: row.chineseMedicinalCode,
                        comName: row.chineseMedicinal,
                    }
                    this.goPage('/ch-medicinal-price',obj)
                }else{
                    this.$message.warning('暂无此价格公示！')
                    return
                }
            },
            //中药材基本信息
            getBaseInfo(text) {
                this.$api.get("chm/ChineseMaterial/queryChineseMaterialByComName", {
                    comName: text,
                    })
                    .then((res) => {
                        this.baseList = res.data || {}
                    })
                    .finally(() => {
                        // this.loading = false;
                    });
            },
            goSecond(data) {
                this.activeName = 'second'
                this.$refs.second.init(data)
            },

            remoteMethod(keyword) {
                this.dataLoading = true
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.success) {
                            this.option = res.data

                            if (Object.keys(this.model).length === 0 && this.option.length > 0) {
                                this.model = this.option[0]
                                this.loadData()
                            }
                        } else {
                            this.message = res.message
                        }
                    })
                    .finally(() => {
                        this.dataLoading = false
                    })
            },
            loadData() {
                this.$refs.first.init(this.model)
            },

            remoteMethod1(keyword) {
                this.dataLoading1 = true
                this.$api.get('setting/drugsComponent/queryDrugsComponentListByKeyword', {keyword}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.success) {
                            this.option1 = res.data

                            if (Object.keys(this.model1).length === 0 && this.option1.length > 0 && !this.$route.query.txt) {
                                this.model1 = this.option1[0]
                                this.loadData1()
                            }
                        } else {
                            this.message1 = res.message
                        }
                    })
                    .finally(() => {
                        this.dataLoading1 = false
                    })
            },
            loadData1() {
                console.log('成分',this.model1);
                this.$refs.second.init(this.model1)
                this.getBaseInfo(this.model1.component)
            },
        }
    }
</script>